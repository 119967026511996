import React, { useState, useEffect } from "react";
import styles from "./HomeLayout.module.scss";

import { MaxWidth } from "../../components";
import { HomeNavbar } from "../HomeNav";
import { HomeFooter } from '../HomeFooter'
import { HomeNavbar2 } from "../HomeNavBar2";

export const HomeLayout = ({ children, isFooter }) => {
  const [showNavbar, setShowNavbar] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setShowNavbar(true);
      } else {
        setShowNavbar(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <React.Fragment>
      {showNavbar?<HomeNavbar />:<HomeNavbar2/>}
        
      <React.Fragment>
        <div className={styles.layoutContainer}>{children}</div>
        {isFooter && <HomeFooter />}
      </React.Fragment>
    </React.Fragment>
  );
};
