import React, {useState, useEffect} from 'react'
import styles from './Membership.module.scss'
import { Layout } from '../../views/Layout'
import { Separator } from '../../components'
import { useNotification } from "../../hooks/notificationProvider";
import { Plans } from '../BillingPage/components/Plans/Plans';
import { getUserData } from '../../Api/businessprofile';
import Cookies from 'js-cookie';
import { getSubscriptionDetail } from '../../Api/stripe';



const Membership = () => {
    const { triggerNotification } = useNotification();
    const [userData,setUserData] = useState();
    const [subscriptionDate,setSubServicecriptionDate] = useState();
    const [productsLoading, setProductsLoading] = useState(false);
   const [userState,setUserState]= useState(false)
   
    const token = Cookies.get('token')
  
    const fetchData = async () => {
      let response;
      try {
  
        if (token) {
          response = await getUserData(token);
  
          if (response?.status == 200) {
            setUserData(response?.data?.data?.status)
          } else {
            console.error('Error fetching user data:');
          }
  
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    const fetchSubscriptionData = async () => {
      let response;
      try {
  
        if (token) {
          response = await getSubscriptionDetail(token);
          setSubServicecriptionDate(response?.data?.subscription)
          if (response?.status == 200) {
          } else {
            console.error('Error fetching user data:');
          }
  
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
    function formatDate(dateString) {
      if (!dateString) return ""; 
      const date = new Date(dateString);
      const formattedDate = date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
      return formattedDate;
  }

    useEffect(() => {
      fetchData()
      fetchSubscriptionData()
    }, [userState,setUserState]);

    return (
        <>
            <Layout isLoggedIn={true} isFooter={true} isContainer>
                <div className={styles.container}>
                    <div className={styles.headingFlex}>
                        <div className={styles.heading}>Current Membership:</div>
                        <div className={styles.statusSubheading}>{userData}</div>
                    </div>
                    <div className={styles.headingFlex} style={{marginTop:"10px"}}>
                    <div className={styles.heading}>Membership Period:</div>
                    <div className={styles.subscriptionText}>
                  {userData == 'free' ? "Lifetime" : `${formatDate(subscriptionDate?.currentPeriodStart)} To ${formatDate(subscriptionDate?.currentPeriodEnd)}`}
                </div>
                </div>
                    <Separator />
                <Plans isloading={productsLoading} setUserState={setUserState} />
                </div>
            </Layout >

        </>
    )
}

export default Membership
