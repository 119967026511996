import React, { useEffect, useState } from "react";
import AuthStyles from "./AuthStyles.module.scss";
import { Field, Formik, Form as FormikForm } from "formik";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { FormHelperText } from '@mui/material';
import { userSignUp } from "../../../Api/businessprofile";
import 'react-phone-number-input/style.css'
import PhoneInput,{  isValidPhoneNumber } from 'react-phone-number-input'
import * as yup from "yup";

import { Layout } from "../../../views/Layout";
import { Separator, Spinner } from "../../../components";
import { LabeledInput } from "../../../components/LabeledInput/LabeledInput";

import { useNavigate } from 'react-router-dom';

import { useNotification } from "../../../hooks/notificationProvider";

const LoginPageValidationSchema = yup.object().shape({
  pointOfContact: yup
    .string()
    .required("Point Of Contact is required")
    .min(3, "Name must be 3 characters long")
    .max(70, "Maximum 70 characters are allowed"),
  name: yup
    .string()
    .required("Name is required")
    .min(3, "Name must be 3 characters long")
    .max(70, "Maximum 70 characters are allowed"),
  email: yup
    .string()
    .email("Invalid email")
    .required("Email is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be 8 characters long")
    .max(16, "Maximum 16 characters are allowed")
    .matches(
      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Password must contain at least one letter, one number, and one special character"
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Password must match")
    .required("Confirm Password is required"),
  zip_code: yup
    .string()
    .required("Zip Code is required")
    .matches(/^\d{5}$/, "Invalid zip code format"),
  address: yup
    .string()
    .required("Address is required"),
  service: yup
    .string()
    .required("Service is required"),
  sub_service: yup
    .string()
    .required("Sub Service is required"),
    web_address: yup
    .string()
    .required("Website Address is required")
    .matches(
        /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+(\/[a-zA-Z0-9#]+\/?)*$/,
        "Website Address must be a valid URL"
    )
    .matches(
        /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+(\/[a-zA-Z0-9#]+\/?)*$/,
        "Website Address must be a clickable URL"
    ),



});


export const JoinPage = () => {


  const [selectService, setSelectService] = useState('');
  const [subService, setSubService] = useState('');
  const [phone, setPhone] = useState()
  const [touched, setTouched] = useState(false);

  const subServicesMap = {
    'Legal Services': ['Traffic Law Attorneys', 'Criminal Defense Lawyers', 'Legal Aid Services', 'Civil Rights Attorneys', 'Legal Consultation Services', 'Accident and Injury Lawyers'],
    'Food and Beverage': ['Restaurants', 'Cafes', 'Catering services', 'Food trucks'],
    'Retail': ['Clothing and Apparel', 'Electronics', 'Furniture', 'Specialty Shops'],
    'Health and Wellness': ['Gyms and Fitness centers', 'Healthcare services', 'Spa and Wellness centers'],
    'Entertainment': ['Movie theaters', 'Event organizers', 'Amusement parks'],
    'Automotive': ['Car dealerships', 'Auto repair shops', 'Car rentals'],
    'Home and Garden': ['Home improvement', 'Landscaping', 'Interior design'],
    'Technology': ['Software development', 'IT services', 'Electronics repair'],
    'Education': ['Schools and universities', 'Tutoring services', 'Educational supplies'],
    'Real Estate': ['Realtors', 'Property management', 'Construction companies'],
    'Travel and Tourism': ['Travel agencies', 'Hotels and accommodations', 'Tour operators'],
    'Arts and Culture': ['Art galleries', 'Performing arts venues'],
    'Sports and Recreation': ['Sports clubs', 'Recreation centers', 'Sporting goods stores'],
    'Finance': ['Banks', 'Investment firms', 'Financial advisors'],
    'Professional Services': ['Marketing and Advertising', 'Public Relations', 'Human Resources', 'Accounting and Finance', 'Cleaning services', 'Consulting'],
    'Mental Health Resources': ['']
  };


  // const handleChange = (event) => {
  //   setService(event.target.value);
  // };

    console.log("phone",phone)
  const handleServiceChange = (event) => {
    const selectedService = event.target.value;
    setSelectService(selectedService);
    setSubService('');
  };

  const handleSubServiceChange = (event) => {
    setSubService(event.target.value);
  };

  const handleBlur = () => {
    setTouched(true);
  };
  const { triggerNotification } = useNotification();

  const initialValues = {
    pointOfContact: "",
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    address: "",
    service: "",
    sub_service: "",
    zip_code: "",
    web_address: ""
  }
  const navigate = useNavigate();
  const handleSubmitForm = async (values, { setSubmitting, resetForm }) => {
    const { pointOfContact, name, email, password, confirmPassword, service, sub_service, zip_code, address, web_address } = values;
    console.log('values', values);
    console.log('phone length',phone.length)
     if (phone.length <= 4) {
      handleBlur()
          return;
        }
    try {
      const response = await userSignUp(pointOfContact, name, email, password, confirmPassword, phone, service, sub_service, zip_code, address, web_address)
      if (response?.status == 201) {

        console.log(`Success`);
        navigate("/email-success");
        setSubmitting(false);
      } else if (response?.response?.status == 500) {
        console.log(`${response?.response?.data?.msg}`);
        triggerNotification({
          message: response?.response?.data?.msg,
          type: "error",
        });
      }
      else if (response?.response?.status == 422) {
        console.log(`${response?.response?.data?.msg}`);
        triggerNotification({
          message: response?.response?.data?.msg,
          type: "error",
        });
      }
      else if (response?.response?.status == 404) {
        console.log(`${response?.response?.data?.msg}`);
        triggerNotification({
          message: response?.response?.data?.msg,
          type: "error",
        });
      }
      else if (response?.response?.status == 401) {
        console.log(`${response?.response?.data?.msg}`);
        triggerNotification({
          message: response?.response?.data?.msg,
          type: "error",
        });
      }
      else if (response?.response?.status == 403) {
        console.log(`${response?.response?.data?.msg}`);
        triggerNotification({
          message: response?.response?.data?.msg,
          type: "error",
        });
      }
      else if (response?.response?.status == 400) {
        console.log(`${response?.response?.data?.msg}`);
        triggerNotification({
          message: response?.response?.data?.msg,
          type: "error",
        });
      }
      // setIsLoading(false);
    } catch (error) {
      setSubmitting(false);
      console.log(error)
    }
    console.log(values);
    // setIsLoading(false);
    setSubmitting(false);


  };

  return (
    <Layout isLoggedIn={false} isFooter={true}>
      <div className={AuthStyles.pageContainer}>
        <div className={AuthStyles.formContainer}>
          <div className={AuthStyles.heading}>
          Business Directory SignUp
          </div>
          <Separator height="30px" />
          <Separator height="30px" />
          <Formik
            initialValues={initialValues}
            validationSchema={LoginPageValidationSchema}
            onSubmit={handleSubmitForm}
            validateOnBlur
            validateOnChange
          >
            {({ isSubmitting }) => (
              <FormikForm style={{ width: "100%" }}>
                <div className={AuthStyles.fields} style={{ display: "flex" }}>
                  <div className={AuthStyles.fieldItem}>
                  <Field name="pointOfContact">
                  {({ field, meta }) => (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <LabeledInput
                        {...field}
                        placeholder="Point Of Contact"
                        type="text"
                        style={{ maxWidth: '335px', overflow: 'hidden' }}
                        className={AuthStyles.customfield}
                      />
                      {meta.touched && meta.error && (
                        <div style={{ color: 'red', marginTop: '5px',fontSize:"12px", fontWeight:"500" }}>{meta.error}</div>
                      )}
                    </div>
                    )}
                  </Field>

                  </div>
                  <div className={AuthStyles.fieldItem} style={{ marginLeft: "40px" }}>
                    <Field name="name">
                      {({ field, meta }) => (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <LabeledInput
                          {...field}
                          placeholder="Full Name"
                          type="text"
                          style={{ maxWidth: '335px', overflow: 'hidden' }}
                          className={AuthStyles.customfield}
                        />
                        {meta.touched && meta.error && (
                        <div style={{ color: 'red', marginTop: '5px',fontSize:"12px", fontWeight:"500" }}>{meta.error}</div>
                      )}
                        </div>
                      )}
                    </Field>
                  </div>
                </div>

                <Separator height="15px" />
                <div className={AuthStyles.fields} style={{ display: "flex" }}>
                  <div className={AuthStyles.fieldItem}>
                    <Field name="email">
                      {({ field, meta }) => (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <LabeledInput
                          {...field}
                          placeholder="Email"
                          type="email"
                          style={{ maxWidth: '335px', overflow: 'hidden' }}
                          className={AuthStyles.customfield}
                        />
                        {meta.touched && meta.error && (
                        <div style={{ color: 'red', marginTop: '5px',fontSize:"12px", fontWeight:"500" }}>{meta.error}</div>
                        )}
                        </div>
                      )}
                    </Field>
                  </div>
                  <div className={AuthStyles.fieldItem} style={{ marginLeft: "40px" }}>
                    <Field name="phone">
                      {({ field, meta }) => (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <PhoneInput
                      international
                      countryCallingCodeEditable={false}
                      defaultCountry="US"
                      placeholder="Enter phone number"
                      value={phone}
                      onChange={setPhone}
                      onBlur={handleBlur}
                      error={meta.touched && meta.error ? meta.error : null}
                    />
                       {touched && !phone && (
                  <div style={{ color: 'red', marginTop: '5px', fontSize: "12px", fontWeight: "500" }}>
                    Phone number is required.
                  </div>
                )}
                {phone?.length<=4 && (
                  <div style={{ color: 'red', marginTop: '5px', fontSize: "12px", fontWeight: "500" }}>
                    Phone number is required.
                  </div>
                )}
                    </div>
                      )}
                    </Field>
                   
                    
                  </div>
                </div>

                <Separator height="15px" />
                <div className={AuthStyles.fields} style={{ display: "flex" }}>
                  <div className={AuthStyles.fieldItem}>
                    <Field name="password">
                      {({ field, meta }) => (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <LabeledInput
                          {...field}
                          placeholder="Password"
                          type="password"
                        />
                         {meta.touched && meta.error && (
                        <div style={{ color: 'red', marginTop: '5px',fontSize:"12px", fontWeight:"500" }}>{meta.error}</div>
                        )}
                        </div>
                      )}
                    </Field>
                  </div>
                  <div className={AuthStyles.fieldItem} style={{ marginLeft: "40px" }}>
                    <Field name="confirmPassword">
                      {({ field, meta }) => (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <LabeledInput
                          {...field}
                          placeholder="Confirm Password"
                          type="password"
                        />
                        {meta.touched && meta.error && (
                        <div style={{ color: 'red', marginTop: '5px',fontSize:"12px", fontWeight:"500" }}>{meta.error}</div>
                        )}
                      </div>
                      )}
                    </Field>
                  </div>

                </div>


                <Separator height="18px" />

                <div className={AuthStyles.fields} style={{ display: "flex" }}>


                  <div className={AuthStyles.fieldItem}>
                    <Field name="zip_code">
                      {({ field, meta }) => (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <LabeledInput
                          {...field}
                          placeholder="Zip Code"
                          type="number"
                          style={{ maxWidth: '335px', overflow: 'hidden' }}
                          className={AuthStyles.customfield}
                        />
                        {meta.touched && meta.error && (
                        <div style={{ color: 'red', marginTop: '5px',fontSize:"12px", fontWeight:"500" }}>{meta.error}</div>
                        )}
                        </div>
                      )}
                    </Field>
                  </div>

                  <div className={AuthStyles.fieldItem} style={{ marginLeft: "40px" }}>
                    <Field name="address">
                      {({ field, meta }) => (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <LabeledInput
                          {...field}
                          placeholder="Address"
                          type="text"
                          style={{ maxWidth: '335px', overflow: 'hidden' }}
                          className={AuthStyles.customfield}
                        />
                        {meta.touched && meta.error && (
                        <div style={{ color: 'red', marginTop: '5px',fontSize:"12px", fontWeight:"500" }}>{meta.error}</div>
                        )}
                        </div>
                      )}
                    </Field>
                  </div>
                </div>

                <Separator height="18px" />

                <div className={AuthStyles.fields} style={{ display: "flex" }}>
                  <div className={AuthStyles.fieldItem} >
                    <Field name="service">
                      {({ field, meta }) => (
                        <Box sx={{ minWidth: 120, borderRadius: '50px', marginTop: "5px" }}>
                          
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label" sx={{ zIndex: '-99' }}>Service</InputLabel>
                            <Select
                              {...field}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Service"
                              onChange={(e) => {
                                field.onChange(e);
                                handleServiceChange(e);
                              }}
                              onBlur={(e) => field.onBlur(e)}
                              sx={{
                                borderRadius: '10px',
                                padding: '2px',
                              }}
                              error={!!meta.error && meta.touched}
                              message={meta.touched && meta.error}

                            >
                              <MenuItem value="">Select a service</MenuItem>
                              <MenuItem value="Legal Services">Legal Services</MenuItem>
                              <MenuItem value="Food and Beverage">Food and Beverage </MenuItem>
                              <MenuItem value="Retail">Retail</MenuItem>
                              <MenuItem value="Health and Wellness">Health and Wellness</MenuItem>
                              <MenuItem value="Entertainment">Entertainment</MenuItem>
                              <MenuItem value="Automotive">Automotive</MenuItem>
                              <MenuItem value="Home and Garden">Home and Garden</MenuItem>
                              <MenuItem value="Technology">Technology</MenuItem>
                              <MenuItem value="Education">Education</MenuItem>
                              <MenuItem value="Real Estate">Real Estate</MenuItem>
                              <MenuItem value="Travel and Tourism">Travel and Tourism</MenuItem>
                              <MenuItem value="Arts and Culture">Arts and Culture</MenuItem>
                              <MenuItem value="Sports and Recreation">Sports and Recreation</MenuItem>
                              <MenuItem value="Finance">Finance</MenuItem>
                              <MenuItem value="Professional Services">Professional Services</MenuItem>
                              <MenuItem value="Mental Health Resources ">Mental Health Resources
                              </MenuItem>
                            </Select>

                          </FormControl>
                          {meta.error && meta.touched && (
                            <FormHelperText error>{meta.error}</FormHelperText>
                          )}
                        </Box>
                      )}
                    </Field>
                  </div>


                  <div className={AuthStyles.fieldItem} style={{ marginLeft: "40px" }}>
            
                  <Field name="sub_service">
                      {({ field, meta }) => (
                        <Box sx={{ minWidth: 120, borderRadius: '50px', marginTop: "5px" }}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label" sx={{ zIndex: '-99' }}>Sub Service</InputLabel>
                            <Select
                              {...field}
                              labelId="sub-service-label"
                              // id="demo-simple-select"
                              label="Sub Service"
                              onChange={(e) => {
                              field.onChange(e);
                              handleSubServiceChange(e);
                            }}
                            disabled={!selectService}
                            onBlur={(e) => field.onBlur(e)}
                            sx={{
                              borderRadius: '10px',
                              padding: '2px',
                            }}
                              error={!!meta.error && meta.touched}
                              message={meta.touched && meta.error}

                            >
                            <MenuItem value="">Select a sub service</MenuItem>
                            {subServicesMap[selectService] && subServicesMap[selectService].map((subServiceOption) => (
                              <MenuItem key={subServiceOption} value={subServiceOption}>{subServiceOption}</MenuItem>
                            ))}
                            </Select>

                          </FormControl>
                          {meta.error && meta.touched && (
                            <FormHelperText error>{meta.error}</FormHelperText>
                          )}
                        </Box>
                      )}
                    </Field>
                </div>

                </div>

                <Separator height="18px" />

                <div className={AuthStyles.fields} style={{ display: "flex" }}>
                  <div className={AuthStyles.fieldItem}>
                    <Field name="web_address">
                      {({ field, meta }) => (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <LabeledInput
                          {...field}
                          placeholder="Enter Website Address"
                          type="text"
                          style={{ maxWidth: '335px', overflow: 'hidden' }}
                          className={AuthStyles.customfield}
                        />
                        {meta.touched && meta.error && (
                        <div style={{ color: 'red', marginTop: '5px',fontSize:"12px", fontWeight:"500" }}>{meta.error}</div>
                        )}
                        </div>
                      )}
                    </Field>
                  </div>

                </div>

                <Separator height="18px" />

                <div className={AuthStyles.fields} style={{ display: "flex" }}>
                </div>

                <Separator height="30px" />

                <Separator height="10px" />

                <Separator height="10px" />

                <Separator height="15px" />
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <button className={AuthStyles.authButton} type="submit" onClick={()=>handleBlur()}>
                    {isSubmitting ? (
                      <Spinner small={true} color={"#fff"} />
                    ) : (
                      "Business Sign up"
                    )}
                  </button>
                </div>
              </FormikForm>
            )}
          </Formik>

        </div>
      </div>
    </Layout>
  );
};
