import React from "react";

import { Layout } from "../../../src/views/Layout";
import { TermsContent } from "./components/TermsContent/TermsContent";


export const TermsCondition = ({  }) => {


  return (
    <Layout isLoggedIn={false} isFooter={true}>
     <TermsContent/>
    </Layout>
  );
};
