// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Checkbox_container__rcoYQ {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Checkbox_input__Ota1r {
  opacity: 0;
  width: 0;
  height: 0;
  margin: 0;
  position: absolute;
}

.Checkbox_label__OW4Sw {
  height: 14px;
  min-width: 14px;
  border: 1px solid #000;
  background: transparent;
  color: #000;
  border-radius: 2px;
  font-size: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.Checkbox_labeledtext__WNmKZ {
  color: #000;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 10px;
}

@media only screen and (max-width: 350px) {
  .Checkbox_labeledtext__WNmKZ {
    font-size: 9px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/base/CheckBox/Checkbox.module.scss","webpack://./src/scss/_colors.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;AADF;;AAIA;EACE,UAAA;EACA,QAAA;EACA,SAAA;EACA,SAAA;EACA,kBAAA;AADF;;AAIA;EACE,YAAA;EACA,eAAA;EACA,sBAAA;EACA,uBAAA;EACA,WCrBM;EDsBN,kBAAA;EACA,eAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;AADF;;AAIA;EACE,WChCM;EDiCN,oBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,iBAAA;AADF;;AAOA;EACE;IACE,cAAA;EAJF;AACF","sourcesContent":["@use \"../../../scss/colors\";\n\n.container {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n\n.input {\n  opacity: 0;\n  width: 0;\n  height: 0;\n  margin: 0;\n  position: absolute;\n}\n\n.label {\n  height: 14px;\n  min-width: 14px;\n  border: 1px solid colors.$black;\n  background: transparent;\n  color: colors.$black;\n  border-radius: 2px;\n  font-size: 10px;\n  cursor: pointer;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n}\n\n.labeledtext {\n  color: colors.$black;\n  font-family: Poppins;\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  margin-left: 10px;\n}\n\n\n\n\n@media only screen and (max-width: 350px) {\n  .labeledtext {\n    font-size: 9px;\n  }\n  \n  }","$black: #000;\n$white: #fff;\n\n$yellow: #FFF75E;\n$red: #ff5454;\n$grey-50: #f8f8fa;\n$grey-300: #afafc1;\n$grey-400: #8b8ba0;\n\n$backgroundColor: #f9f9f9;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Checkbox_container__rcoYQ`,
	"input": `Checkbox_input__Ota1r`,
	"label": `Checkbox_label__OW4Sw`,
	"labeledtext": `Checkbox_labeledtext__WNmKZ`
};
export default ___CSS_LOADER_EXPORT___;
