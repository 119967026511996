import React from "react";
import styles from "./HomeFooter.module.scss";
import { Link, useNavigate } from "react-router-dom";
import RAlogo from "../../../src/resources/images/logo.png"
import phone from "../../../src/resources/images/phone.png";
import address from "../../../src/resources/images/address.png";
import aboutus from "../../../src/resources/images/about-us.png";
import facebook from "../../../src/resources/images/facebook.png";
import twitter from "../../../src/resources/images/twitter.png";
import youtube from "../../../src/resources/images/youtube.png";
import email from "../../../src/resources/images/email.png";
import instagram from "../../../src/resources/images/instagram.png";

export const HomeFooter = () => {
  const navigate = useNavigate();
  return (
    <footer className={styles.footer}>
      <div className={styles.container} >
        <div className={styles.footerContent}>
       
          <div className={styles.footerList}>
           <img src={RAlogo} width={150} height={50}/>
            <Link className={styles.footerListItem} to="#" style={{paddingTop:"20px"}}>
            Your Personal Safety App
            </Link>
            <div style={{display:"flex", paddingTop:"20px"}}>
              <img src={facebook} width={20} height={20} style={{marginRight:"30px"}}/>
              <img src={instagram} width={20} height={20} style={{marginRight:"30px"}}/>
              <img src={twitter} width={20} height={20} style={{marginRight:"30px"}}/>
            </div>
          </div>
          
          <div className={styles.footerList}>
            <div className={styles.heading}>Contact</div>
            <p className={styles.footerListItem} style={{paddingTop:"20px"}}>
             <img src={phone} width={20} height={20}/> 404-793-4233
            </p>
            <p className={styles.footerListItem} style={{paddingTop:"10px", cursor:"pointer"}}>
            <img src={email} width={20} height={20}/> Info@rileysalert.com
            </p>
            {/* <p className={styles.footerListItem} onClick={()=>navigate('/about-us')} style={{paddingTop:"10px", cursor:"pointer"}}>
            <img src={aboutus} width={20} height={20}/> About Us
            </p> */}
            <p className={styles.footerListItem} onClick={()=>navigate('/contact-us')} style={{paddingTop:"10px", cursor:"pointer"}}>
            <img src={address} width={20} height={20}/> Contact Us
            </p>
          </div>
          <div className={styles.footerList}>
            <div className={styles.heading}>Legal</div>
            <Link className={styles.footerListItem}  style={{paddingTop:"20px"}} to="/terms-condition">
              Terms & Conditions
            </Link>
            <Link className={styles.footerListItem}  style={{paddingTop:"20px"}} to="/privacy-policy">
              Privacy Policy
            </Link>
            <Link className={styles.footerListItem}  style={{paddingTop:"20px"}} to="/sms-service">
            A2P SMS Service
            </Link>
          </div>
        </div>
        <div className={styles.footerRightText}>
          © 2024 Rileys Alert LTD. All rights reserved
        </div>
      </div>
    </footer>
  );
};
