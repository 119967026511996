// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes Spinner_spin__DSOqJ {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(359deg);
  }
}
.Spinner_spinner__H2HDD {
  width: 42px;
  height: 42px;
  border-width: 4px;
  border-radius: 50%;
  animation: Spinner_spin__DSOqJ 1.5s linear 0s infinite;
}

.Spinner_small__FXiKO {
  width: 27px;
  height: 27px;
  border-width: 3px;
}

.Spinner_extraSmall__FZ2b4 {
  width: 12px;
  height: 12px;
  border-width: 2px;
}`, "",{"version":3,"sources":["webpack://./src/components/Spinner/Spinner.module.scss"],"names":[],"mappings":"AAEA;EACE;IACE,oBAAA;EADF;EAIA;IACE,yBAAA;EAFF;AACF;AAKA;EACE,WAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,sDAAA;AAHF;;AAMA;EACE,WAAA;EACA,YAAA;EACA,iBAAA;AAHF;;AAMA;EACE,WAAA;EACA,YAAA;EACA,iBAAA;AAHF","sourcesContent":["@use \"../../scss/colors\";\n\n@keyframes spin {\n  from {\n    transform: rotate(0);\n  }\n\n  to {\n    transform: rotate(359deg);\n  }\n}\n\n.spinner {\n  width: 42px;\n  height: 42px;\n  border-width: 4px;\n  border-radius: 50%;\n  animation: spin 1.5s linear 0s infinite;\n}\n\n.small {\n  width: 27px;\n  height: 27px;\n  border-width: 3px;\n}\n\n.extraSmall {\n  width: 12px;\n  height: 12px;\n  border-width: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinner": `Spinner_spinner__H2HDD`,
	"spin": `Spinner_spin__DSOqJ`,
	"small": `Spinner_small__FXiKO`,
	"extraSmall": `Spinner_extraSmall__FZ2b4`
};
export default ___CSS_LOADER_EXPORT___;
