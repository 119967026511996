import React from "react";
import { ContactLayout } from "../../views/ContactLayout";
import { Content } from "./components/Content/Content";

export const DeleteAccount = () => {
  return (
    <ContactLayout isLoggedIn={false} isFooter={true}>
      <Content />
    </ContactLayout>
  );
};
