import React from 'react';

import { ContactLayout } from "../../views/ContactLayout";
import { Content } from "./components/Content/Content";



export const ContactUs = () => {
    return (
        <ContactLayout isLoggedIn={false} isFooter={true}>
            <Content />
        </ContactLayout>
    );
};