// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MaxWidth_container__pMbeS {
  width: 100%;
  max-width: 980px;
  margin: 0 30px;
}

.MaxWidth_fullHeight__znY6V {
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/MaxWidth/MaxWidth.module.scss","webpack://./src/scss/_variables.scss"],"names":[],"mappings":"AAEA;EACI,WAAA;EACA,gBCDO;EDEP,cAAA;AADJ;;AAIA;EACI,YAAA;AADJ","sourcesContent":["@use \"../../scss/variables\";\n\n.container {\n    width: 100%;\n    max-width: variables.$maxwidth;\n    margin: 0 30px;\n}\n\n.fullHeight {\n    height: 100%;\n}","$navbar: 70px;\n$sidebar-width: 227px;\n\n$maxwidth: 980px;\n\n$spacing-xs: 3px;\n$spacing-sm: 5px;\n$spacing-md: 10px;\n$spacing-lg: 15px;\n$spacing-xl: 20px;\n$spacing-2xl: 40px;\n\n$width-size-menu: 93px;\n\n$width-modal-sm: 464px;\n$width-modal-md: 650px;\n$width-modal-lg: 940px;\n$width-modal-xl: 1008px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `MaxWidth_container__pMbeS`,
	"fullHeight": `MaxWidth_fullHeight__znY6V`
};
export default ___CSS_LOADER_EXPORT___;
