import React, { useState } from "react";
import styles from "./Content.module.scss";
import phone from "../../../../resources/images/phone-white.png";
import location from "../../../../resources/images/location-white.png";
import mail from "../../../../resources/images/mail-white.png";
import facebook from "../../../../resources/images/facebook-white.png";
import instagram from "../../../../resources/images/instagram-white.png";
import twitter from "../../../../resources/images/twitterNewIcon.png";
import axios from "axios";
import { useNotification } from "../../../../hooks/notificationProvider";

export const Content = () => {
  const { triggerNotification } = useNotification();
  const [formData, setFormData] = useState({
    email: "",
    first_name: "",
    last_name: "",
    subject: "",
    message: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PUBLIC_BASE_URL}/user/contact`,
        formData
      );
      if (response.status === 200) {
        triggerNotification({
          message: response?.data?.msg,
          type: "success",
        });
      } else {
        triggerNotification({
          message: response?.data?.msg,
          type: "error",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      // Extracting a meaningful error message
      let errorMessage = "An unknown error occurred";
      if (error.response && error.response.data && error.response.data.msg) {
        errorMessage = error.response.data.msg;
      } else if (error.message) {
        errorMessage = error.message;
      }
      triggerNotification({
        message: errorMessage,
        type: "error",
      });
    }
  };

  return (
    <div className={`container ${styles.portalDiv}`}>
      <div
        className="row shadow"
        style={{
          marginTop: "30px",
          width: "80%",
          marginBottom: "30px",
          borderRadius: "20px",
        }}
      >
        <div className="col-lg-8 col-md-12 p-4">
          <form onSubmit={handleSubmit} className="d-flex flex-column w-100">
            <p
              className="font-weight-bold text-maroon"
              style={{
                fontSize: "28px",
                padding: "20px",
                color: "maroon",
                font: "Poppins",
                fontWeight: "700",
              }}
            >
              Send us a Message
            </p>
            <label htmlFor="email" className="form-label">
              Name
            </label>
            <div className="d-flex mb-3">
              <input
                type="text"
                name="first_name"
                placeholder="First name"
                className="form-control mr-2"
                value={formData.first_name}
                onChange={handleChange}
                required
              />
              <input
                type="text"
                name="last_name"
                placeholder="Last name"
                className="form-control"
                style={{ marginLeft: "10px" }}
                value={formData.last_name}
                onChange={handleChange}
              />
            </div>
            <div className={styles.position_relative}>
              <label htmlFor="email" className="form-label">
                Email <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="email"
                name="email"
                placeholder=""
                className="form-control mr-2"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className={styles.position_relative}>
              <label htmlFor="email" className="form-label">
                Subject <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                name="subject"
                placeholder=""
                className="form-control"
                value={formData.subject}
                onChange={handleChange}
              />
            </div>
            <div className={styles.position_relative}>
              <label htmlFor="email" className="form-label">
                Message <span style={{ color: "red" }}>*</span>
              </label>
              <textarea
                name="message"
                placeholder=""
                className="form-control"
                rows="5"
                value={formData.message}
                onChange={handleChange}
              />
            </div>
            <div className="d-flex justify-content-end">
              <button
                type="submit"
                className="btn btn-danger"
                style={{
                  width: "150px",
                  height: "50px",
                  borderRadius: "20px",
                  textTransform: "uppercase",
                  background:
                    "linear-gradient(to right, rgb(248, 29, 30), rgb(170, 5, 6))",
                }}
              >
                Send Message
              </button>
            </div>
          </form>
        </div>
        <div
          className="col-lg-4 col-md-12 bg-danger text-white p-4"
          style={{
            borderTopRightRadius: "20px",
            borderBottomRightRadius: "20px",
            background:
              "linear-gradient(to right, rgb(248, 29, 30), rgb(170, 5, 6))",
          }}
        >
          <p className="font-weight-bold" style={{ fontSize: "28px" }}>
            Contact Us
          </p>
          <p className="font-weight-semibold" style={{ fontSize: "18px" }}>
            USA
          </p>
          <div className={styles.footerList}>
            <p className={styles.footerListItem}>
              <img src={location} width={15} height={15} />
              &nbsp;&nbsp;Conyers, USA
            </p>
            <p className={styles.footerListItem}>
              <img src={phone} width={15} height={15} /> 404-793-4233
            </p>
            <p className={`${styles.footerListItem} cursor-pointer`}>
              <img src={mail} width={15} height={15} /> Info@rileysalert.com
            </p>
          </div>

          <div className="d-flex">
            <a href="https://www.facebook.com" target="_blank">
              <img src={facebook} width={20} height={20} />
            </a>
            <a href="https://www.instagram.com" target="_blank">
              <img
                src={instagram}
                width={20}
                height={20}
                style={{ marginLeft: "10px" }}
              />
            </a>
            <a href="https://www.twitter.com" target="_blank">
              <img
                src={twitter}
                width={18}
                height={18}
                style={{ marginLeft: "10px" }}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
