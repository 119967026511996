// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SidebarItem_container__aPlr7 {
  width: 100%;
  border-radius: 5px 0 0 5px;
  padding: 16px 24px;
  box-sizing: border-box;
  background-color: transparent;
  color: #000;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}
.SidebarItem_container__aPlr7.SidebarItem_isSelected__Rw\\+G6 {
  background: linear-gradient(to bottom, rgb(248, 29, 30), rgb(170, 5, 6));
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/views/Sidebar/components/SidebarItem.module.scss","webpack://./src/scss/_colors.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,0BAAA;EACA,kBAAA;EACA,sBAAA;EACA,6BAAA;EACA,WCRM;EDSN,oBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,qBAAA;AADF;AAGE;EAEE,wEAAA;EACA,WClBI;ADgBR","sourcesContent":["@use \"../../../scss/colors\";\n\n.container {\n  width: 100%;\n  border-radius: 5px 0 0 5px;\n  padding: 16px 24px;\n  box-sizing: border-box;\n  background-color: transparent;\n  color: colors.$black;\n  font-family: Poppins;\n  font-size: 20px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  text-decoration: none;\n\n  &.isSelected {\n    // background-color: colors.$yellow;\n    background: linear-gradient(to bottom, rgb(248, 29, 30), rgb(170, 5, 6));\n    color: colors.$white;\n  }\n}\n","$black: #000;\n$white: #fff;\n\n$yellow: #FFF75E;\n$red: #ff5454;\n$grey-50: #f8f8fa;\n$grey-300: #afafc1;\n$grey-400: #8b8ba0;\n\n$backgroundColor: #f9f9f9;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SidebarItem_container__aPlr7`,
	"isSelected": `SidebarItem_isSelected__Rw+G6`
};
export default ___CSS_LOADER_EXPORT___;
