import React from "react";
import styles from "./PrivacyContent.module.scss";

import { Separator, MaxWidth } from "../../../../components";

export const PrivacyContent = () => {
    return (
        <div className={styles.container}>
            <MaxWidth>
                <div className={styles.contentContainer}>
                    <div className={styles.content}>
                        <Separator height="5px" />
                        <div className={styles.subHeading}>
                        Effective Date: November 16, 2023
                        </div>
                        <Separator />
                        <Separator />
                        <div className={styles.text}>
                        Riley's Alert ("we", "us", or "our") respects your privacy and is committed to protecting your personal information. This Privacy Policy explains what information we collect, how we use it, and your choices regarding your information.
                        </div>
                        <Separator />
                        <div className={styles.text}>
                        <ol>
                            <li>Information We Collect</li>
                            <div className={styles.subText} style={{marginTop:"10px"}}>
                            We collect the following information from you:
                             </div>
                             <div className={styles.subText} style={{marginTop:"10px"}}>
                             Account Information: When you create an account, we collect your name, email address, phone number, and password.
                             </div>
                             <div className={styles.subText} style={{marginTop:"10px"}}>
                             Demographic Information: You may choose to provide us with additional information such as your address, date of birth, and gender.
                             </div>
                             <div className={styles.subText} style={{marginTop:"10px"}}>
                             Location Information: We collect your GPS location when you activate Riley's Alert and search for businesses in the directory. You can turn off location sharing at any time in your device settings.
                             </div>
                             <div className={styles.subText} style={{marginTop:"10px"}}>
                             Usage Information: We collect information about how you use the App, such as the features you use, the frequency of your use, and the content you record.                             </div>
                             <div className={styles.subText} style={{marginTop:"10px"}}>
                             Device Information: We collect information about your device, such as its type, operating system, and IP address.
                             </div>
                             <div className={styles.subText} style={{marginTop:"10px"}}>
                             Payment Information: If you subscribe to the paid plan or add additional loved ones, we collect your payment information through Stripe.
                             </div>
                        </ol>
                        
                        </div>
                        <Separator />
                        <div className={styles.text}>
                        <ol start={2}>
                            <li>How We Use Your Information</li>
                            <div className={styles.subText} style={{marginTop:"10px"}}>
                            We use your information to:
                             </div>
                             <div className={styles.subText} style={{marginTop:"10px"}}>
                             Provide and improve the App, including sending push notifications and text messages in case of Riley's Alert activation.                             </div>
                             <div className={styles.subText} style={{marginTop:"10px"}}>
                             Manage your account and provide customer support.
                                   Send you marketing communications (you can opt out of these at any time).
                             </div>
                             <div className={styles.subText} style={{marginTop:"10px"}}>
                             Analyze App usage and improve our services.
                                Comply with legal requirements and enforce our Terms of Service.
                             </div>
                        </ol>
                        
                        </div>
                        <Separator />
                        <div className={styles.text}>
                        <ol start={3}>
                            <li>Sharing Your Information</li>
                            <div className={styles.subText} style={{marginTop:"10px"}}>
                            We may share your information with the following third-party partners:
                             </div>
                             <div className={styles.subText} style={{marginTop:"10px"}}>
                             Service providers: We may share your information with service providers who help us operate the App, such as cloud storage providers and payment processors.
                           </div>
                             <div className={styles.subText} style={{marginTop:"10px"}}>
                             Loved ones: If you activate Riley's Alert, we will share your GPS location with the loved ones you designate.
                             </div>
                             <div className={styles.subText} style={{marginTop:"10px"}}>
                             Law enforcement: We may share your information with law enforcement if required by law or if we believe it is necessary to protect the safety of others.
                             </div>
                        </ol>
                        
                        </div>
                        <Separator />
                        <div className={styles.text}>
                        <ol start={4}>
                            <li>Your Choices</li>
                            <div className={styles.subText} style={{marginTop:"10px"}}>
                            You have the following choices regarding your information:
                             </div>
                             <div className={styles.subText} style={{marginTop:"10px"}}>
                             You can access and update your account information in the App settings.
                           </div>
                             <div className={styles.subText} style={{marginTop:"10px"}}>
                             You can opt out of marketing communications by emailing us at info@rileysalert.com.
                             </div>
                             <div className={styles.subText} style={{marginTop:"10px"}}>
                             You can control location sharing in your device settings.
                             </div>
                             <div className={styles.subText} style={{marginTop:"10px"}}>
                             You can request deletion of your account by emailing us at info@rileysalert.com.
                             </div>
                        </ol>
                        
                        </div>
                        <Separator />
                        <div className={styles.text}>
                        <ol start={5}>
                            <li>Data Security</li>
                            <div className={styles.subText} style={{marginTop:"10px"}}>
                            We take reasonable measures to protect your information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or method of electronic storage is 100% secure. We will however use the latest technology to help us protect your information.
                             </div>
                             
                        </ol>
                        
                        </div>
                        <Separator />
                        <div className={styles.text}>
                        <ol start={6}>
                            <li>Children's Privacy</li>
                            <div className={styles.subText} style={{marginTop:"10px"}}>
                            The App is not intended for children under the age of 13. We do not knowingly collect or solicit personal information from children under the age of 13. If you are a parent or guardian and you believe your child has provided us with personal information, please contact us at info@rileysalert.com.
                             </div>
                             
                        </ol>
                        
                        </div>
                        <Separator />
                        <div className={styles.text}>
                        <ol start={7}>
                            <li>International Transfers</li>
                            <div className={styles.subText} style={{marginTop:"10px"}}>
                            Your information may be transferred to and processed in countries other than your own. These countries may have different data privacy laws than your own country.
                             </div>
                             
                        </ol>
                        
                        </div>
                        <Separator />
                        <div className={styles.text}>
                        <ol start={8}>
                            <li>Changes to this Privacy Policy</li>
                            <div className={styles.subText} style={{marginTop:"10px"}}>
                            We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on the App. Your continued use of the App after the changes are posted constitutes your acceptance of the new Privacy Policy.
                             </div>
                             
                        </ol>
                        
                        </div>
                        <Separator />
                        <div className={styles.text}>
                        <ol start={9}>
                            <li>Contact Us</li>
                            <div className={styles.subText} style={{marginTop:"10px"}}>
                            If you have any questions about this Privacy Policy, please contact us at info@rileysalert.com.
                             </div>
                             
                        </ol>
                        
                        </div>
                       
                    </div>
                </div>
            </MaxWidth>
        </div>
    );
};
