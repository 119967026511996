import React from "react";
import styles from "./SmsContent.module.scss";

import { Separator, MaxWidth } from "../../../../components";

export const SmsContent = () => {
    return (
        <div className={styles.container}>
            <MaxWidth>
                <div className={styles.contentContainer}>
                    <div className={styles.content}>                      
                        <Separator />
                        <div className={styles.text}>
                        Here is a suggested Terms and Conditions template that aims to align with industry requirements for A2P SMS campaigns:
                        </div>
                        <Separator />
                        <div className={styles.text}>
                        Terms and Conditions for Riley’s Alert A2P SMS Service
                        </div>
                        <Separator />
                        <div className={styles.text}>
                        <ol>
                            <li>Overview These Terms and Conditions govern your use of the [Campaign Name] SMS messaging service (the "Service") provided by [Company Name]. By opting-in to receive messages from this Service, you agree to be bound by these Terms.</li>
                        </ol>
                        
                        </div>
                        <Separator />
                        <div className={styles.text}>
                        <ol start={2}>
                            <li>User Consent and Opt-Out </li>
                            <div className={styles.text} style={{marginTop:"10px"}}>
                        2.1 &nbsp;&nbsp;&nbsp;You must provide express consent to receive SMS messages from this Service by completing the opt-in process. 2.2 You can revoke your consent and opt-out of receiving messages at any time by following the opt-out instructions in each message or contacting us at [contact info].
                        </div>
                        </ol>
                       
                        
                        </div>
                        <Separator />
                        <div className={styles.text}>
                        <ol start={3}>
                            <li>Service Description</li>
                            <div className={styles.text} style={{marginTop:"10px"}}>
                        3.1 &nbsp;&nbsp;&nbsp;The Service allows us to send you SMS notifications, alerts, confirmations, location shares, and other messages related to Riley’s Alert’s personal safety app.
                        </div>
                        <div className={styles.text} style={{marginTop:"10px"}}>
                        3.2 &nbsp;&nbsp;&nbsp;Message frequency will vary based on your activity but will not exceed 20 messages per day unless urgent notifications are required.
                        </div>
                        </ol>
                        
                        </div>
                        <Separator />
                        <div className={styles.text}>
                        <ol start={4}>
                            <li>User Data </li>
                            <div className={styles.text} style={{marginTop:"10px"}}>
                        4.1 &nbsp;&nbsp;&nbsp;We will collect your mobile number and other user data as per our Privacy Policy in order to provide the Service.
                        </div>
                        <div className={styles.text} style={{marginTop:"10px"}}>
                        4.2 &nbsp;&nbsp;&nbsp;We employ reasonable data security and privacy safeguards but cannot guarantee 100% protection from unauthorized access or use.
                        </div>
                        </ol>
                        
                        </div>
                        <Separator />
                        <div className={styles.text}>
                        <ol start={5}>
                            <li>Compliance with Laws We aim to fully comply with the Telephone Consumer Protection Act (TCPA), CTIA Messaging Principles, wireless carrier policies, and other applicable laws and regulations governing the Service.</li>
                        </ol>
                        
                        </div>
                        <Separator />
                        <div className={styles.text}>
                        <ol start={6}>
                            <li>Service Limitations and Modifications</li>
                            <div className={styles.text} style={{marginTop:"10px"}}>
                        6.1 &nbsp;&nbsp;&nbsp;We do not warrant or guarantee successful delivery of every message due to factors beyond our control.
                        </div>
                        <div className={styles.text} style={{marginTop:"10px"}}>
                        6.2 &nbsp;&nbsp;&nbsp; We reserve the right to modify, suspend or discontinue the Service or these Terms at any time.
                        </div>
                             
                        </ol>
                        
                        </div>
                        <Separator />
                        <div className={styles.text}>
                        <ol start={7}>
                            <li>Limitation of Liability To the maximum extent permitted by law, we are not liable for any direct, indirect, incidental or consequential damages arising from your use of the Service.</li>
                             
                        </ol>
                        
                        </div>
                        <Separator />
                        <div className={styles.text}>
                        <ol start={8}>
                            <li>General Provisions</li>
                            <div className={styles.text} style={{marginTop:"10px"}}>
                        8.1 &nbsp;&nbsp;&nbsp;Termination: You may terminate your use of the Service at any time per section 2.2.
                        </div>
                        <div className={styles.text} style={{marginTop:"10px"}}>
                        8.2 &nbsp;&nbsp;&nbsp; Governing Law: These Terms shall be governed by the laws of Georgia. 8.3 Dispute Resolution: Any disputes shall be submitted to Georgia arbitration/state courts in Cobb county
                        </div>
                             
                        </ol>
                        
                        </div>
                        <Separator />
                        <div className={styles.text}>
                            <div className={styles.subText} style={{marginTop:"10px"}}>
                            By opting into the Service, you signify your agreement to these Terms and Conditions. We may update these Terms from time to time by notifying users.
                             </div>
                        </div>
                       
                    </div>
                </div>
            </MaxWidth>
        </div>
    );
};
