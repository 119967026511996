// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SuccessBox_pageContainer__K\\+oZ5 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.SuccessBox_pageContainer__K\\+oZ5 .SuccessBox_formContainer__BTBQy {
  width: 70%;
  height: auto;
  border-radius: 10px;
  color: #fff;
  padding: 30px;
  margin: 90px 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.SuccessBox_pageContainer__K\\+oZ5 .SuccessBox_heading__qAdUx {
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.SuccessBox_pageContainer__K\\+oZ5 .SuccessBox_text__uSR5V {
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media only screen and (max-width: 500px) {
  br {
    display: none !important;
  }
  .SuccessBox_heading__qAdUx {
    font-size: 26px !important;
  }
  .SuccessBox_text__uSR5V {
    font-size: 16px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/AuthPages/Login/components/SuccessBox/SuccessBox.module.scss","webpack://./src/scss/_colors.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;AADF;AAIA;EACE,UAAA;EACA,YAAA;EACA,mBAAA;EAEA,WCfM;EDgBN,aAAA;EACA,iBAAA;EACA,sBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AAHF;AAOA;EACE,WC5BM;ED6BN,kBAAA;EACA,oBAAA;EAEA,eAAA;EACA,kBAAA;EAEA,gBAAA;EACA,mBAAA;AAPF;AAUA;EACE,WCxCM;EDyCN,kBAAA;EACA,oBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AARF;;AAaA;EACA;IACE,wBAAA;EAVA;EAYA;IACD,0BAAA;EAVC;EAYA;IACE,0BAAA;EAVF;AACF","sourcesContent":["@use \"../../../../../scss/colors\";\n\n.pageContainer {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  \n\n.formContainer {\n  width: 70%;\n  height: auto;\n  border-radius: 10px;\n  // background-color: colors.$black;\n  color: colors.$white;\n  padding: 30px;\n  margin: 90px 30px;\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n \n}\n\n.heading {\n  color: colors.$black;\n  text-align: center;\n  font-family: Poppins;\n  // font-size: 21px;\n  font-size: 30px;\n  font-style: normal;\n  // font-weight: 600;\n  font-weight: 400;\n  line-height: normal;\n}\n\n.text {\n  color: colors.$black;\n  text-align: center;\n  font-family: Poppins;\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n}\n\n}\n\n@media only screen and (max-width: 500px) {\nbr{\n  display: none !important;\n}\n  .heading{\n font-size: 26px !important;\n  }\n  .text{\n    font-size: 16px !important;\n  }\n\n}\n","$black: #000;\n$white: #fff;\n\n$yellow: #FFF75E;\n$red: #ff5454;\n$grey-50: #f8f8fa;\n$grey-300: #afafc1;\n$grey-400: #8b8ba0;\n\n$backgroundColor: #f9f9f9;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageContainer": `SuccessBox_pageContainer__K+oZ5`,
	"formContainer": `SuccessBox_formContainer__BTBQy`,
	"heading": `SuccessBox_heading__qAdUx`,
	"text": `SuccessBox_text__uSR5V`
};
export default ___CSS_LOADER_EXPORT___;
