import React, { useState, useEffect } from "react";
import styles from "./PlanItem.module.scss";
import Cookies from "js-cookie";
import { cancelSubscription, createCheckout } from "../../../../Api/stripe";
import { getUserData } from "../../../../Api/businessprofile";
import { useNotification } from "../../../../hooks/notificationProvider";

export const PlanItem = ({setUserState}) => {
  const [isMobile, setIsMobile] = useState(false);
  const [userData, setUserData] = useState();
  const [showModal, setShowModal] = useState(false); 
  const { notification, triggerNotification } = useNotification();
  const [subscriptionUpdate,setSubscriptionUpdate]= useState(false)
  const token = Cookies.get('token')

  const fetchData = async () => {
    let response;
    try {

      if (token) {
        response = await getUserData(token);

        if (response?.status == 200) {
          // console.log(response?.data?.data,"response?.data?.data");
          setUserData(response?.data?.data?.status)
        } else {
          console.error('Error fetching user data:');
        }

      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const subscriptionCancel = async () => {
    let response;
    try {

      if (token) {
        response = await cancelSubscription(token);

        if (response?.status == 200) {
          triggerNotification({
            message: response?.data?.message,
            type: "success",
          });
          setShowModal(false)
          setSubscriptionUpdate(true)
          setUserState(true)
        } else {
          console.error('Error fetching user data:');
        }

      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  useEffect(() => {
    fetchData()
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [subscriptionUpdate]);

  const createStripeCheckout = async (priceId) => {
    try {
      const token = Cookies.get("token")
      const res = await createCheckout(token, priceId);
      if (res?.status == 200) {
        const checkoutUrl = res?.data?.checkoutUrl;
        if (checkoutUrl) {
          window.location.href = checkoutUrl;
        } else {
          console.log("Checkout URL not found in response.");
        }
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const handleFreeSubscriptionChange = () => {
    if(userData=='free')
      {
        triggerNotification({
          message: 'You are already a free user.',
          type: "warning",
        });
        return;
      }
    setShowModal(true); 
  }

  const handlePaidSubscriptionChange = () => {
    if(userData=='paid')
      {
        triggerNotification({
          message: 'You are already a paid user.',
          type: "warning",
        });
        return;
      }
      createStripeCheckout("price_1P8IU706dWei3BV7NVN6APsY")
  }

  return (
    <>
      {showModal &&
        <div className={styles.modalOverlay}>
          <div className={styles.modal}>
            <span className={styles.closeButton} onClick={() => setShowModal(false)}>X</span>
            <div className={styles.modalContent}>
              <p>Are you sure you want to change your membership to a free profile?</p>
              <div className={styles.buttonsContainer}>
                <button className={styles.noButton} onClick={() => setShowModal(false)}>No</button>
                <button className={styles.yesButton} onClick={() => subscriptionCancel()}>Yes</button>
              </div>
            </div>
          </div>
        </div>
      }

      <div className={styles.productcontainer} onClick={handleFreeSubscriptionChange}>
        {userData === "free" ? (<>
          <div className={styles.subscriptionStatus}>Subscribed</div>
        </>) : (null)}
        <div className={styles.productcontainerdetail2}>
          <span className={styles.creditsDetail2}>
            Free
          </span>
        </div>
        <div className={styles.productcontainerdetail2}>
          <span className={styles.packagePrice}>
            $0
            <span>.00</span>
          </span>
        </div>
      </div>


      <div className={styles.productcontainer} onClick={handlePaidSubscriptionChange}>
        {userData === "paid" ? (<>
          <div className={styles.subscriptionStatus}>Subscribed</div>
        </>) : (null)}
        <div className={styles.productcontainerdetail2}>
          <span className={styles.creditsDetail2}>
            Paid
          </span>
        </div>
        <div className={styles.productcontainerdetail2}>
          <span className={styles.packagePrice}>
          $50
            <span>.00</span>
          </span>
        </div>
      </div>
    </>
  );
};
