import React from "react";
import styles from "./HomeLayout.module.scss";

import { MaxWidth } from "../../components";
import { HomeNavbar } from "../HomeNav";
import { HomeFooter } from '../HomeFooter'

export const ContactLayout = ({ children, isLoggedIn,isFooter, isContainer }) => {
  return (
    <React.Fragment>
      <HomeNavbar />
      {/* {!isLoggedIn && ( */}
        <React.Fragment>
          <div className={styles.layoutContainer}>{children}</div>
          {isFooter && <HomeFooter />}
        </React.Fragment>
      {/* )} */}
    </React.Fragment>
  );
};
