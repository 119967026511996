import React from "react";
import CongratulationStyles from "./Consent.module.scss";
import logo from "../../resources/images/rileys logo.png"
import { Separator } from "../../components";
import axios from "axios"
import { useNotification } from "../../hooks/notificationProvider";
import { useEffect } from "react";
import { useState } from "react";

export const Consent = () => {
    const url = new URL(window.location.href);
    const contactId = url.searchParams.get('contactId');
    const userId = url.searchParams.get('userId');
  const { triggerNotification } = useNotification();
  const [contactName,setContactName]=useState("")
  const [firstName,setFirstName]=useState("")
  const [lastName,setLastName]=useState("")
  const [addedById,setAddedById]=useState("")


useEffect(()=>{
    getContactById()
},[])
const getContactById=async(consent)=>{
    const response=await axios
    .get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/contact/${contactId}`)
    .then((response) => {
    if(response?.data?.status==200)
    {
        setContactName(response?.data?.data?.name);
        setFirstName(response?.data?.data?.added_by?.first_name);
        setLastName(response?.data?.data?.added_by?.last_name);
        setAddedById(response?.data?.data?.added_by?._id);
    }
    else
    {
        triggerNotification({
            message: response?.data?.msg,
            type: "error",
          });
    }
})
.catch((error) => {
  console.log(error)
});
    }

    const optIn=async(consent)=>{
    const response=await axios
    .patch(`${process.env.REACT_APP_PUBLIC_BASE_URL}/contact/consent`, 
    {
        contactId:contactId,
        added_by:addedById,
        opt_in:consent
    }
    )
    .then((response) => {
    if(response?.data?.status==200 && response?.data?.error==false )
    {
        triggerNotification({
            message: response?.data?.msg,
            type: "success",
          });
    }
    else if(response?.data?.status==200  && response?.data?.error==true )
    {
        triggerNotification({
            message: response?.data?.msg,
            type: "error",
          });
    }
    else
    {
        triggerNotification({
            message: response?.data?.msg,
            type: "error",
          });
    }
})
.catch((error) => {
  console.log(error)
});
    }

    return (
        <div className={CongratulationStyles.pageContainer} style={{ marginTop: "100px" }}>
            <div className={CongratulationStyles.formContainer}>
            <div className={CongratulationStyles.heading} style={{ paddingTop: "10px" }}>
                    <img src={logo} height={100} width={200}/>
                </div>
                <div className={CongratulationStyles.heading} style={{ paddingTop: "30px" }}>
                <span>Personal Safety App</span>
                </div>
                <div className={CongratulationStyles.heading} style={{ paddingTop: "30px" }}>
                <span>Hello {contactName} , {firstName} {lastName} has added you as a contact on Riley's Alert in the event of an emergency. Do you consent to being one of their contacts?</span>
                </div>
               
                <div className={CongratulationStyles.buttonContainer} style={{ paddingTop: "50px" }}>
                    <button className={CongratulationStyles.authButton} style={{ marginTop: "50px" }} onClick={() => {optIn("accepted")}}>
                        Yes
                    </button>
                    <button className={CongratulationStyles.authButton2} style={{ marginTop: "50px" }} onClick={() => {optIn("rejected")}}>
                        No
                    </button>
                </div>
                <Separator height="30px" />
                <Separator height="15px" />
            </div>
        </div>
    );
};
