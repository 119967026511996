import React from "react";
import styles from "./TermsContent.module.scss";

import { Separator, MaxWidth } from "../../../../components";

export const TermsContent = () => {


    return (
        <div className={styles.container}>
            <MaxWidth>
                <div className={styles.contentContainer}>
                    <div className={styles.content}>
                        <Separator height="5px" />
                        <div className={styles.subHeading}>
                        Welcome to Riley's Alert!
                        </div>
                        <Separator />
                        <Separator />
                        <div className={styles.text}>
                        These Terms of Service ("Terms") govern your use of the Riley's Alert mobile application ("App"). By accessing or using the App, you agree to be bound by these Terms. If you disagree with any part of these Terms, you may not use the App.
                        </div>
                        <Separator />
                        <div className={styles.text}>
                        Services Offered Riley's Alert is a mobile application that allows users to: Record video and audio in case of emergencies using voice activation ("Riley's Alert"). Store recorded videos and audio on Riley's Alert servers. Download recorded videos and audio. Designate loved ones to receive push notifications and text messages with GPS location in case of Riley's Alert activation. Access a directory of businesses categorized by location and type. Read blog posts and participate in a Discord forum.
                        
                        </div>
                        <Separator />
                        <div className={styles.text}>
                        Account and Subscription You can use the App on a subscription based model at 8.99 per month. We also have a 15 day free trial which will require user to add their credit card information and if they wish to no longer use the app they must cancel subscription before the 15 day trial period ends. You can sign up for an account using Google Play or Apple Store. You must be at least 18 years old to use the App.
                    
                        </div>
                                
                        <Separator />
                        <div className={styles.text}>
                        Fees and Payment. The paid subscription and additional loved ones feature require payment through Stripe. All payments are non-refundable. You can cancel your subscription at any time.
                        
                        </div>
                        <Separator/>
                        <div className={styles.text}>
                        Content and User Conduct You are responsible for all content you create or upload to the App. You must not use the App for any illegal or harmful purpose. This includes, but is not limited to, posting content that is: Defamatory or abusive Hateful or discriminatory Violent or threatening Sexually explicit Infringing on the intellectual property rights of others Riley's Alert reserves the right to remove any content that violates these Terms or is otherwise deemed inappropriate.
                        </div>
                        <Separator/>
                        <div className={styles.text}>
                        Data Privacy Riley's Alert collects and uses your personal data to provide and improve the App. You can find more information about our data privacy practices in our Privacy Policy.
                        
                        </div>
                        <Separator/>
                        <div className={styles.text}>
                        Location Services The App uses location services to provide features such as sending GPS location to loved ones and searching for businesses near you. You can opt out of location sharing at any time in your device settings. If the user opts out of allowing Riley's Alert App to use their location, audio/video, SMS, Riley's Alert is not liable for any circumstances as a result.
                        </div>

                        <Separator/>
                        <div className={styles.text}>
                        SMS Messaging Services As part of the Riley's Alert service, we may send SMS text messages to the emergency contacts you designate within the App. These messages may include your GPS location and other critical information in the event you activate the emergency alert feature.
                        </div>
                        <Separator/>
                        <div className={styles.text}>
                        By using the Riley's Alert App and providing the mobile numbers of your designated emergency contacts, you represent and warrant that you have obtained the explicit consent of those contacts to receive SMS alerts and location information from Riley's Alert on their behalf during potential emergency situations.
                        
                        </div>
                        <Separator/>
                        <div className={styles.text}>
                        You agree that the SMS messages sent via the Riley's Alert service are for the sole purpose of sharing time-sensitive safety information and GPS coordinates to facilitate emergency response and assistance. The messaging service is not to be used for any commercial, promotional, or marketing purposes.
                        </div>
                        <Separator/>
                        <div className={styles.text}>
                        We aim to fully comply with the Telephone Consumer Protection Act (TCPA), CTIA Messaging Principles, wireless carrier policies, and all other applicable laws and regulations governing the use of SMS messaging services.
                        
                        </div>
                        <Separator/>
                        <div className={styles.text}>
                        While we strive to deliver SMS alerts reliably, we cannot guarantee successful delivery of every message due to factors beyond our control. We are not liable for any damages arising from delayed, undelivered, or incomplete SMS messages.
                        
                        </div>
                        <Separator/>
                        <div className={styles.text}>
                        We reserve the right to modify or terminate the SMS messaging component of the Riley's Alert service at any time without prior notice.
                        
                        </div>
                        <Separator/>
                        <div className={styles.text}>
                        Video Recording and Incoming Calls If an incoming call is received while video recording is active through the Riley's Alert app, and the call is answered, the video recording will be terminated at that point. If the incoming call is not answered, video recording will continue uninterrupted. Riley's Alert is not liable for any video recording disruptions caused by incoming calls.
                        
                        </div>
                        <Separator/>
                        <div className={styles.text}>
                        While Riley's Alert aims to provide a personal safety app to aid users in emergencies, it is not intended to replace local emergency infrastructure or services. Users should still contact appropriate emergency responders (e.g., police, fire department, ambulance) in situations requiring immediate assistance. Riley's Alert should be used as a supplementary tool for personal safety and should not be relied upon as a substitute for official emergency services
                        
                        </div>
                        <Separator/>
                        <div className={styles.text}>
                        Intellectual Property Riley's Alert owns all intellectual property rights in the App. You may not copy, modify, or distribute the App without our permission.                        
                        </div>
                        <Separator/>
                        <div className={styles.text}>
                        Disclaimer of Warranties The App is provided "as is" and without any warranties, express or implied. Riley's Alert does not warrant that the App will be uninterrupted, error-free, or secure.                        
                        </div>
                        <Separator/>
                        <div className={styles.text}>
                        Limitation of Liability Riley's Alert is not liable for any damages arising out of your use of the App. This includes, but is not limited to, direct, indirect, incidental, consequential, and punitive damages.                        
                        </div>
                        <Separator/>

                        <div className={styles.text}>
                        Termination Riley's Alert may terminate your account or access to the App at any time, for any reason, without notice.                        
                        </div>
                        <Separator/>

                        <div className={styles.text}>
                        Governing Law and Dispute Resolution These Terms are governed by and construed in accordance with the laws of the State of Georgia, without regard to conflict of laws provisions. Any dispute arising out of or relating to these Terms will be resolved by arbitration.                        
                        </div>
                        <Separator/>

                        <div className={styles.text}>
                        Changes to Terms Riley's Alert reserves the right to change these Terms at any time. We will notify you of any changes by posting the new Terms on the App. Your continued use of the App after the changes are posted constitutes your acceptance of the new Terms.                        
                        </div>
                        <Separator/>

                        <div className={styles.text}>
                        Contact Us If you have any questions about these Terms, please contact us at info@rileysalert.com.                        </div>

                    </div>
                </div>
            </MaxWidth>
        </div>
    );
};
