export const RoutePaths = {
  Root: "/",
  Login: "/login",
  Signup: "/signup",
  Home: "/home",
  Pricing: "/pricing",
  Profile: "/profile",
  Billing: "/billing",
  Referrals: "/referrals",
  Support: "/support",
  Onboard: "/onboard",
  PaymentSuccess: "/PaymentSuccess/success",
  EmailVerify: "/verifyEmail",
  PostPayment: "/post-payment",
  EmailSuccess: "/email-success",
  Privacy: "/privacy-policy",
  HowToUse: "/how-to-use",
  Terms: "/terms-condition",
  ResetPassword: "reset-password",
  Join: "/join",
  SuccessBox: "/success_box",
  Membership: "/membership",
  Consent: "/consent",
  SmsService: "sms-service",
  Contact: "/contact-us",
  AboutUs: "/about-us",
  DeleteAccount: "/delete-account",
};
