// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Sidebar_container__wlZSC {
  width: 227px;
  height: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 768px) {
  .Sidebar_container__wlZSC {
    display: none !important;
    width: none !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/views/Sidebar/Sidebar.module.scss","webpack://./src/scss/_variables.scss"],"names":[],"mappings":"AAGA;EACI,YCHY;EDIZ,YAAA;EACA,eAAA;EACA,aAAA;EACA,sBAAA;AAFJ;;AAKA;EACI;IACG,wBAAA;IACA,sBAAA;EAFL;AACF","sourcesContent":["@use \"../../scss/colors\";\n@use \"../../scss/variables\";\n\n.container {\n    width: variables.$sidebar-width;\n    height: 100%;\n    position: fixed;\n    display: flex;\n    flex-direction: column;\n}\n\n@media only screen and (max-width: 768px) {\n    .container {\n       display: none !important;\n       width: none !important;\n    }\n\n}","$navbar: 70px;\n$sidebar-width: 227px;\n\n$maxwidth: 980px;\n\n$spacing-xs: 3px;\n$spacing-sm: 5px;\n$spacing-md: 10px;\n$spacing-lg: 15px;\n$spacing-xl: 20px;\n$spacing-2xl: 40px;\n\n$width-size-menu: 93px;\n\n$width-modal-sm: 464px;\n$width-modal-md: 650px;\n$width-modal-lg: 940px;\n$width-modal-xl: 1008px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Sidebar_container__wlZSC`
};
export default ___CSS_LOADER_EXPORT___;
