import React, { useState } from "react";
import styles from "./Content.module.scss";
import axios from "axios";
import { useNotification } from "../../../../hooks/notificationProvider";

export const Content = () => {
  const { triggerNotification } = useNotification();
  const [formData, setFormData] = useState({
    email: "",
    message: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `https://api.rileysalert.com/api/v1/user/contact`,
        formData
      );
      if (response.status === 200) {
        triggerNotification({
          message: response?.data?.msg,
          type: "success",
        });
        setFormData({
          email: "",
          message: "",
        });
      } else {
        triggerNotification({
          message: response?.data?.msg,
          type: "error",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      // Extracting a meaningful error message
      let errorMessage = "An unknown error occurred";
      if (error.response && error.response.data && error.response.data.msg) {
        errorMessage = error.response.data.msg;
      } else if (error.message) {
        errorMessage = error.message;
      }
      triggerNotification({
        message: errorMessage,
        type: "error",
      });
    }
  };

  return (
    <div className={`container ${styles.portalDiv}`}>
      <div
        className="row shadow"
        style={{
          marginTop: "30px",
          width: "80%",
          marginBottom: "30px",
          borderRadius: "20px",
        }}
      >
        <div className="col-lg-8 col-md-12 p-4">
          <form onSubmit={handleSubmit} className="d-flex flex-column w-100">
            <p
              className="font-weight-bold text-maroon"
              style={{
                fontSize: "28px",
                padding: "20px",
                color: "maroon",
                font: "Poppins",
                fontWeight: "700",
              }}
            >
              Delete Account
            </p>
            <div className={styles.position_relative}>
              <label htmlFor="email" className="form-label">
                Email <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="email"
                name="email"
                placeholder=""
                className="form-control mr-2"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className={styles.position_relative}>
              <label htmlFor="email" className="form-label">
                Reason <span style={{ color: "red" }}>*</span>
              </label>
              <textarea
                name="message"
                placeholder=""
                className="form-control"
                rows="5"
                value={formData.message}
                onChange={handleChange}
                required
              />
            </div>
            <div className="d-flex justify-content-end">
              <button
                type="submit"
                className="btn btn-danger"
                style={{
                  width: "210px",
                  height: "50px",
                  borderRadius: "20px",
                  textTransform: "uppercase",
                  background:
                    "linear-gradient(to right, rgb(248, 29, 30), rgb(170, 5, 6))",
                }}
              >
                Delete Permanently
              </button>
            </div>
          </form>
        </div>
        <div
          className="col-lg-4 col-md-12 bg-danger text-white p-4"
          style={{
            borderTopRightRadius: "20px",
            borderBottomRightRadius: "20px",
            background:
              "linear-gradient(to right, rgb(248, 29, 30), rgb(170, 5, 6))",
          }}
        >
          <p className="font-weight-bold" style={{ fontSize: "28px" }}>
            Information
          </p>
          <ul>
            <li
              className={styles.footerListItem}
              style={{ marginBottom: "14px" }}
            >
              Your account will be deleted after 10 days of request.
            </li>
            <li className={styles.footerListItem}>
              All the data including your email will be removed from the
              database.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
