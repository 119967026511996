import React, { useState } from "react";
import styles from "./Content.module.scss";
import phone from "../../../../resources/images/phone-white.png";
import location from "../../../../resources/images/location-white.png";
import mail from "../../../../resources/images/mail-white.png";
import facebook from "../../../../resources/images/facebook-white.png";
import instagram from "../../../../resources/images/instagram-white.png";
import twitter from "../../../../resources/images/twitter-white.png";
import linkedin from "../../../../resources/images/linkedin-white.png";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useNotification } from "../../../../hooks/notificationProvider";
import ceo from "../../../../resources/images/ceo.png";
import { Separator } from "../../../../components";

export const Content = () => {
  const navigate = useNavigate();
  const { triggerNotification } = useNotification();


  return (
    <>

      <div className={`container ${styles.portalDiv}`}>
      <div className={styles.portalSubDiv}>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <p style={{ font: "Poppins", fontSize: "24px", fontWeight: "800", paddingLeft: "30px",color:"maroon" }}>About Us:</p>
            <p style={{ font: "Poppins", fontSize: "18px", fontWeight: "500", paddingLeft: "30px" }}>
            At Riley’s Alert®, our mission is to provide families with peace of mind and an enhanced sense of security during life's most distressing situations. We understand how quickly circumstances can change and how precious the safety of your loved ones is.
            <Separator/>
           
            That's why we created an innovative app to keep you connected and aware when it matters most. Riley’s Alert®  ensures your designated family members and friends receive instant alerts about critical events or emerging concerns impacting you or your loved ones. Our app encourages safety and provides assurance that help is just a tap away.
           <Separator/>
            <b>Core Features:<br/></b>
            <ul>
              <li><b>SMS Alerts</b> - Rapidly notify your designated personal contacts by a double-tap-app on your smartphone.<br/></li>
              <li><b>GPS Location</b> - Allow your trusted contacts to view your real-time location for precise tracking and timely assistance.<br/></li>
              <li><b>Audio/Video Surveillance</b> - Activate your phone's camera and microphone to capture and transmit footage of your surroundings securely to designated contacts.<br/></li>
            </ul>

            Whether it's a roadside emergency, medical crisis, personal safety threat, or missing child,  Riley’s Alert®  is designed to be a vital lifeline. Our sophisticated app empowers you to instantly engage your support network and emergency services with locational awareness for prompt assistance. 
                        <Separator/>
                        At Riley’s Alert®, we believe that enhanced communication and connectivity can provide an invaluable layer of protection. Our app gives you confidence that your loved ones are always within reach when you need them most. Experience the assurance of Riley’s Alert®  and help safeguard your family today.
             </p>
          </div>
        </div>
        
      </div>
    </>
  );
};
