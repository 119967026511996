import React, { useState } from "react";

import { Layout } from "../../../src/views/Layout";
import { SmsContent } from "./components/SmsContent/SmsContent";


export const SmsService = ({  }) => {


  return (
    <Layout isLoggedIn={false} isFooter={true}>
     <SmsContent/>
    </Layout>
  );
};
