/* eslint-disable jsx-a11y/alt-text */
// Content.js

import React from "react";
import styles from "./Content.module.scss";
import { MaxWidth, Separator } from "../../../../components";
import progressWheel from "../../../../resources/images/ProgressWheel.svg";
import googlePlayStoreImage from "../../../../resources/images/play-store.png";
import appleStoreImage from "../../../../resources/images/app-store.png";
import usaMap from "../../../../resources/images/usa-map.png";
import user from "../../../../resources/images/user.svg";
import texas from "../../../../resources/images/texas-flag.png";
import california from "../../../../resources/images/california-flag.png";
import montana from "../../../../resources/images/montana-flag.png";
import maxico from "../../../../resources/images/new-mexico-flag.png";
import business from "../../../../resources/images/business.svg";
import premium from "../../../../resources/images/premium.svg";
import rating from "../../../../resources/images/rating.svg";
import nearest from "../../../../resources/images/nearest.svg";
import app from "../../../../resources/images/app.svg";
import question from "../../../../resources/images/question.png";
import banner from "../../../../resources/images/banner-2.jpg";
import benefits from "../../../../resources/images/benefits.png";
import work from "../../../../resources/images/work.png";
import userImg from "../../../../resources/images/user.png";
import features from "../../../../resources/images/features.png";
import usage from "../../../../resources/images/usage.png";
import offer from "../../../../resources/images/offer.png";
import logo from "../../../../resources/images/logo.png";

import { useNavigate } from 'react-router-dom';

import state from "../../../../resources/images/alaska-flag.png"
export const Content = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className={styles.container} style={{ backgroundImage: `url(${banner})`,backgroundSize: 'cover',backgroundPosition: 'center', marginTop: "-80px" }}>
        <MaxWidth>
          <div className={styles.contentContainer}>
            <div className={styles.overlay}>
            <img src={logo} className={styles.homeImg}/>
              <p className={styles.bannerText}>Your Personal Safety App.</p>
              <div className={styles.mainDivButton} >
                <button style={{ width: "auto", height: "auto", borderRadius: "10px", border: "none", font: "Poppins", fontSize: "20px",padding:"10px" }} onClick={()=>{navigate("/join")}}>Register As Business Now</button>
                {/* <div className={styles.mainDivIcons}>
                  <img src={googlePlayStoreImage} alt="Google Play Store" style={{ width: "auto", height: "50px",marginLeft:"20px"}} />
                  <img src={appleStoreImage} alt="Apple Store" style={{ width: "auto", height: "50px" ,marginLeft:"16px"}} />
                </div> */}
              </div>
            </div>
            {/* <div className={styles.imageSection}>
              <img src={progressWheel} alt="Generate Alert" style={{ width: "300px", height: "300px" }} />
            </div> */}
          </div>
        </MaxWidth>
      </div>

      {/* <div className={styles.customerContainer}>
      <div className={styles.usersText}>
      <p style={{font:"Poppins", fontSize:"24px", fontWeight:"800"}}>Trusted by over <span style={{color:"maroon"}}>50</span> happy clients in US, including:</p>
      </div>
      <div className={styles.statesContainer}>
      <img src={state} width={100} height={100} style={{marginRight:"60px",marginLeft:"50px"}}/>
      <img src={texas} width={100} height={100} className={styles.imgMargin}/>
      <img src={california} width={100} height={100} className={styles.imgMargin}/>
      <img src={montana} width={100} height={70} className={styles.imgMargin} style={{marginTop:"16px"}}/>
      <img src={maxico} width={100} height={70} className={styles.imgMargin} style={{marginTop:"16px"}}/>
      </div>
      </div> */}

      {/* <div className={styles.servicesContainer}>
        <div className={styles.servicesSubContainer}>
          <p style={{ font: "Poppins", fontSize: "18px", fontWeight: "700", color: "#822648" }}>Services</p>
          <p style={{ font: "Poppins", fontSize: "26px", fontWeight: "800", }}>What we offer</p>
          <p style={{ font: "Poppins", fontSize: "18px", fontWeight: "500", }}>We offer our following trusted services to our business clients </p>
        </div>
        <div className={styles.serviceMainDiv}>
      <div className={styles.serviceDiv}>
      <img src={business} width={100} height={100}/>
      <p className={styles.serviceHeadingContent} >Business</p>
      <p className={styles.serviceContent}>We offer your business<br/> to our trusted users</p>

      </div>
      <div className={styles.serviceDiv}>
      <img src={premium} width={100} height={100}/>
      <p className={styles.serviceHeadingContent}>Premium profile</p>
      <p className={styles.serviceContent}>We offer you a premium<br/> profile to be at top</p>

      </div>
      <div className={styles.serviceDiv}>
      <img src={nearest} width={100} height={100}/>
      <p className={styles.serviceHeadingContent} style={{paddingLeft:"10px",paddingRight:"10px"}} >Nearest clients</p>
      <p className={styles.serviceContent} >We offer you our<br/> nearest clients</p>

      </div>
      <div className={styles.serviceDiv}>
      <img src={rating} width={100} height={100}/>
      <p className={styles.serviceHeadingContent}>Rating</p>
      <p className={styles.serviceContent}>We offer you our user's<br/> rating to your business</p>

      </div>
      </div>
      </div> */}

      <div className={styles.portalDiv}>
        <div className={styles.portalSubDiv}>
          <img src={question} className={styles.portalImage2} />
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <p style={{ font: "Poppins", fontSize: "24px", fontWeight: "800", paddingLeft: "30px",color:"maroon" }}>What is Riley’s Alert®?</p>
            <p style={{ font: "Poppins", fontSize: "18px", fontWeight: "500", paddingLeft: "30px" }}>Riley’s Alert® is an innovative personal safety app that provides a needed layer of personal security and assurance for you and designated contacts during distressing situations.</p>
          </div>
        </div>
      </div>
      <div className={styles.portalDiv}>
        <div className={styles.portalSubDiv}>
          <img src={benefits} className={styles.portalImage2} />
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <p style={{ font: "Poppins", fontSize: "24px", fontWeight: "800", paddingLeft: "30px",color:"maroon" }}>Benefits of Riley’s Alert®</p>
            <p style={{ font: "Poppins", fontSize: "18px", fontWeight: "500", paddingLeft: "30px" }}>With Riley’s Alert®, you'll experience the comfort of knowing your loved ones are just a tap away from being alerted to your circumstances. Our app promotes personal safety by enabling you to quickly communicate your status and get assistance in any risky situation.</p>
          </div>
        </div>
      </div>

      <div className={styles.portalDiv4}>
        <div className={styles.portalSubDiv}>
          <img src={features} className={styles.portalImage2} />
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <p style={{ font: "Poppins", fontSize: "24px", fontWeight: "800", paddingLeft: "30px",color:"maroon" }}>Riley’s Alert® Feature Suite</p>
            <p style={{ font: "Poppins", fontSize: "18px", fontWeight: "500", paddingLeft: "30px" }}>
            Riley’s Alert® offers peace of mind through key personal safety features:
            <ul>
                <li>SMS Text Alerts</li>
                <li>GPS Location Tracking</li>
                <li>Surveillance Capabilities and offsite storage </li>
              </ul>
              <b>SMS Text Alerts</b>
              <Separator height="0px"/>
              Notify up to 3 designated personal contacts sharing your location and need for assistance.              <Separator height="0px"/>

              <b>GPS Location Tracking</b>              <Separator height="0px"/>

              Provide your GPS coordinates and real-time location to your designated contacts for potential improvement of coordination and response with emergency personnel.                    <Separator height="0px"/>
     
              <b>Video and Audio Capabilities</b>              <Separator height="0px"/>

              Activate your phone's camera and microphone to capture video and audio of your surroundings and transmit to secure servers.              
              
              </p>
          </div>
        </div>
      </div>

      <div className={styles.portalDiv3}>
        <div className={styles.portalSubDiv}>
          <img src={userImg} className={styles.portalImage2} />
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <p style={{ font: "Poppins", fontSize: "24px", fontWeight: "800", paddingLeft: "30px",color:"maroon" }}>Who it’s for:  </p>
            <p style={{ font: "Poppins", fontSize: "18px", fontWeight: "500", paddingLeft: "30px" }}>
            <b>Everyone with a smartphone.</b>
            <Separator height="0px"/>
            Because emergencies strike at any time, Riley’s Alert® provides a needed layer of personal security and peace of mind for anyone. Our innovative app is designed to be a vital lifeline for subscribers, no matter your age or circumstances.
            <Separator height="0px"/>
               
            <b>Students & Educators</b>
            <Separator height="0px"/>
            Riley’s Alert® allows students and staff to instantly notify 3 designated contacts, e.g., loved ones or first responders, share their GPS location, and capture critical video and audio.               
            <Separator height="0px"/>
            
            <b>Seniors & Caregivers</b>
            <Separator height="0px"/>
            Riley’s Alert® enables you to rally family members during a medical emergency, e.g., a loved one with dementia who wanders off, to pinpoint their location and enable a rapid response.                 
            <Separator height="0px"/>
               
            <b>Workers</b>
            <Separator height="0px"/>
            Riley’s Alert® provides a discreet way to signal awareness when personal safety is compromised at work.               
            <Separator height="0px"/>
               
            <b>Parents & Families</b>
            <Separator height="0px"/>
            Riley’s Alert® provides your children with an added sense of security by enabling them to alert you about their whereabouts while walking home, being pulled over, or encountering a troubling situation.               
            <Separator height="0px"/>
           
            <b>Solo Adventurers</b>
            <Separator height="0px"/>
            Riley’s Alert® allows you to check in, and let loved ones track your journey for added safety while exploring alone, e.g., running, hiking, biking, camping and so forth                  
            <Separator height="0px"/>
          
            <b>Community Members</b>
            <Separator height="0px"/>
            Riley’s Alert® permits you to rapidly engage support circles when the unexpected occurs, e.g. , severe weather, civic unrest, or dealing with a car breakdown in a remote area.                 
            <Separator height="0px"/>
            With powerful capabilities like single tap-activated alerts, GPS tracking, and audio and video recording, Riley’s Alert® is an invaluable tool for fostering safer communities and providing assurance to people of all ages and backgrounds. Experience the peace of mind it offers today.
                 
                  </p>
          </div>
        </div>
      </div>

      <div className={styles.portalDiv}>
        <div className={styles.portalSubDiv}>
          <img src={work} className={styles.portalImage2} />
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <p style={{ font: "Poppins", fontSize: "24px", fontWeight: "800", paddingLeft: "30px",color:"maroon" }}>How it works</p>
            <p style={{ font: "Poppins", fontSize: "18px", fontWeight: "500", paddingLeft: "30px" }}>
              <ol>
                <li>Tap the alert button to instantly signal your designated contacts of your emergency.</li>
              <li>Text messages to designated personal contacts …Alert generated and sent to  designated personal contacts.</li>
              <li>Video or audio record the incident (optional)</li>
              </ol>
                  </p>
          </div>
        </div>
      </div>

      

      {/* <div className={styles.portalDiv}>
        <div className={styles.portalSubDiv}>
          <img src={features} className={styles.portalImage2} />
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <p style={{ font: "Poppins", fontSize: "24px", fontWeight: "800", paddingLeft: "30px",color:"maroon" }}>Key Features:</p>
            <p style={{ font: "Poppins", fontSize: "18px", fontWeight: "500", paddingLeft: "30px" }}>
              <ul>
                <li>SMS Text Alerts</li>
                <li>GPS Location Tracking</li>
                <li>Surveillance Capabilities and offsite storage</li>
              </ul>
                  </p>
          </div>
        </div>
      </div> */}

   

      <div className={styles.portalDiv4}>
        <div className={styles.portalSubDiv}>
          <img src={usage} className={styles.portalImage2} />
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <p style={{ font: "Poppins", fontSize: "24px", fontWeight: "800", paddingLeft: "30px",color:"maroon" }}>When You Need Riley’s Alert® Most: </p>
            <p style={{ font: "Poppins", fontSize: "18px", fontWeight: "500", paddingLeft: "30px" }}>In times of distress, Riley’s Alert® rapidly engages your support network in a variety of emergencies and high-risk situations. Here are just some examples of when the app's capabilities could prove invaluable:

            <Separator height="0px"/>
            <ul>
              <li>Medical Emergencies</li>
              <li>Missing Persons</li>
              <li>Natural Disasters</li>
              <li> Outdoor Adventures</li>
              <li>Personal Safety</li>
              <li>Public Unrest</li>
              <li> Roadside Assistance</li>
              <li>School Shootings</li>
              <li>Traffic Stops</li>
              <li>Workplace Emergencies</li>
            </ul>
            With Riley’s Alert® on your smartphone, you're never alone when facing an emergency. Our app keeps you connected to your loved ones with just a tap.
            <Separator height="0px"/>
            Don't go another day without the assurance of Riley’s Alert®. Download our personal safety app today and experience the convenience of enhanced security and rapid assistance, just a tap away. Download the App Today!
              </p>
          </div>
        </div>
      </div>

      <div className={styles.portalDiv}>
        <div className={styles.portalSubDiv}>
          <img src={offer} className={styles.portalImage2} />
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <p style={{ font: "Poppins", fontSize: "24px", fontWeight: "800", paddingLeft: "30px",color:"maroon" }}>What we offer:</p>
              <p style={{ font: "Poppins", fontSize: "18px", fontWeight: "500", paddingLeft: "30px" }}>Riley’s Alert® offers a business directory that helps customers find needed services, giving businesses a chance to position themselves as industry leaders. By creating a detailed profile, businesses can increase their visibility. Users can easily locate nearby businesses and rate their experience, linking it to the business profile. This system benefits customers by providing reliable services and helps businesses enhance their reputation and reach.
              </p>
          </div>
        </div>
      </div>

      {/* <div className={styles.customerContainer}>
      <div className={styles.ratingContainer}>
      <div style={{display:"flex",flexDirection:"column",width:"200px",justifyContent:"center",alignItems:"center",marginRight:"20px"}}>
      <p style={{fontSize:"38px",font:"Poppins",fontWeight:"800",color:"maroon"}}>500+</p>
      <p style={{fontSize:"24px",font:"Poppins",fontWeight:"600"}}>Deliever alerts</p>
      </div>
      <div style={{display:"flex",flexDirection:"column",width:"200px",justifyContent:"center",alignItems:"center",marginRight:"20px"}}>
      <p style={{fontSize:"38px",font:"Poppins",fontWeight:"800",color:"maroon"}}>150+</p>
      <p style={{fontSize:"24px",font:"Poppins",fontWeight:"600"}}>Business Profiles</p>
      </div>
      <div style={{display:"flex",flexDirection:"column",width:"200px",justifyContent:"center",alignItems:"center",marginRight:"20px"}}>
      <p style={{fontSize:"38px",font:"Poppins",fontWeight:"800",color:"maroon"}}>3</p>
      <p style={{fontSize:"24px",font:"Poppins",fontWeight:"600"}}>Trusted users</p>
      </div>
      <div style={{display:"flex",flexDirection:"column",width:"200px",justifyContent:"center",alignItems:"center",marginRight:"20px"}}>
      <p style={{fontSize:"38px",font:"Poppins",fontWeight:"800",color:"maroon"}}>50</p>
      <p style={{fontSize:"24px",font:"Poppins",fontWeight:"600"}}>Rating</p>
      </div>
      </div>
      </div> */}

      {/* <div className={styles.servicesContainer}>
      <div className={styles.servicesSubContainer}>
          <p style={{ font: "Poppins", fontSize: "18px", fontWeight: "700", color: "#822648" }}>Testimonial</p>
          <p style={{ font: "Poppins", fontSize: "26px", fontWeight: "800", }}>What our customers say</p>
          <p style={{ font: "Poppins", fontSize: "18px", fontWeight: "500", }}>Our customers love our services and they have following remaks about us</p>
        </div>
      </div>

      <div className={styles.mapDiv}>
  <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "90%", backgroundImage: `url(${usaMap})`, backgroundSize: "cover", backgroundPosition: "center", boxShadow: "0 4px 8px rgba(0,0,0,0.1)", borderRadius: "10px" }}>
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", padding: "20px", marginBottom: "20px", marginLeft: "20px" }}>
      <img src={user} width={70} height={70} />
    </div>
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", padding: "20px", width: "100%" }}>
      <img src={user} width={100} height={100} />
      <p style={{ font: "Poppins", fontSize: "18px", fontWeight: "700" }}>I can send alerts to my contacts whenever I am in trouble and need them.</p>
      <p style={{ font: "Poppins", fontSize: "18px", fontWeight: "700", color: "maroon", marginTop: "-15px" }}>Keya Akter</p>
      <p style={{ font: "Poppins", fontSize: "18px", fontWeight: "600", marginTop: "-15px" }}>(Customer)</p>
      <img src={user} width={70} height={70} />
      <div className={styles.dummyImage2}>
        <img src={user} width={70} height={70} />
      </div>
      <div className={styles.dummyImage3}>
        <img src={user} width={70} height={70} />
      </div>
    </div>
    <div className={styles.dummyImage}>
      <img src={user} width={70} height={70} />
    </div>
  </div>
</div> */}


<div style={{display:"flex",justifyContent:"center",marginTop:"60px"}}>
<div style={{background:"white",display:"flex", flexDirection:"column",alignItems:"center", justifyContent:"center",width:"600px",height:"160px",marginBottom:"-40px",boxShadow: "0 4px 8px rgba(0,0,0,0.1)", borderRadius:"10px"}}>
 <p style={{font:"Poppins",fontSize:"26px", fontWeight:"800",textAlign:"center",color:"maroon"}}>Are you a business? <br/> </p> 
 <p style={{font: "Poppins", fontSize: "18px", fontWeight: "500", paddingLeft: "30px"}}>Register for our business directory today</p>
 <div style={{ display: "flex",alignItems: "center"}}>
 <button style={{ width: "auto", height: "auto",padding:"10px", borderRadius: "10px", border: "none", font: "Poppins", fontSize: "20px",color:"white",background:"maroon",backgroundColor: 'black' }} onClick={()=>{navigate("/join")}}>Register As Business Now</button>
                </div>
</div>
</div>



    </>
  );
};

