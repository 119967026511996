import React, { useState } from "react";
import styles from "./HomeNav.module.css";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { RoutePaths } from "../../pages/routePaths";
import { MaxWidth } from "../../components";

import Cookies from 'js-cookie';

import { useSelector } from "react-redux";
import { useEffect } from "react";
import { SidebarItem } from "../Sidebar/components/SidebarItem";
import RAlogo from "../../../src/resources/images/logo.png"
import { useDispatch } from "react-redux";

export const HomeNavbar = () => {
  let isLogin = useSelector((state) => state.isLogin);
  const dispatch = useDispatch();
  const push = useNavigate();
  const { pathname, hash } = useLocation();
  const token = Cookies.get('token')
  const userDetail = token;

  const getHomeRoute = () => {
    if (userDetail === null) {
      return `${RoutePaths.Root}`;
    } else {
      return `${RoutePaths.Root}`;
    }
  };

  const [showNav, setShowNav] = useState(false);

  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const closeNav = () => {
    setShowNav(false);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        closeNav();
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (

    <>
      <nav className={styles.navbar}>
        <MaxWidth>
          <div className={styles.container}>
            <Link className={styles.logoContainer} to="/home">

              <img src={RAlogo} width="130" height="55" alt="" />
            </Link>

            <div className={styles.NavToggle} onClick={toggleNav}>
              {showNav ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M18 6L6 18M6 6l12 12" />
                </svg>
              ) : (
                <>
                  <div className={styles.bar}></div>
                  <div className={styles.bar}></div>

                </>
              )}
            </div>

            <div className={`${styles.navItemsContainer} ${showNav ? styles.active : ''}`}>
                  <div className={styles.mobilehide}>
                    <Link className={`${styles.navItem}`} to={`${RoutePaths.Root}`}>
                      Home
                    </Link>
                    {/* <Link className={`${styles.navItem}`} to={`/#`}>
                      Contact
                    </Link> */}
                    
                    <Link className={`${styles.navItem}`} to={`${RoutePaths.Login}`}>
                    Business Sign in
                    </Link>
                    <Link className={`${styles.navItem}`} to={`${RoutePaths.Join}`}>
                    Business Sign up
                    </Link>
                   
                    <Link className={`${styles.navItem}`} to="#">
                          Blog
                      </Link>
                      <Link className={`${styles.navItem}`} to={`${RoutePaths.AboutUs}`}>
                    About us
                    </Link>
                      {/* <Link className={`${styles.navItem}`} to={`${RoutePaths.Contact}`}>
                          Contact US
                      </Link> */}
                   
                  </div>

                  <div className={`${styles.additionalNavItems}`}>
                    <div style={{ display: "flex", flexDirection: "column", paddingTop: "25px" }}>
                      <SidebarItem

                        path={RoutePaths.Root}
                        isSelected={pathname === RoutePaths.Profile}
                      >
                        Home
                      </SidebarItem>

                      <Link className={`${styles.navItem}`} to="/login">
                        <div style={{ fontFamily: 'Poppins', fontSize: '20px', fontStyle: 'normal', fontWeight: 400, lineHeight: 'normal', textDecoration: 'none' }} >
                        Business Sign in
                        </div>

                      </Link>
                      <Link className={`${styles.navItem}`} to="/join" style={{paddingTop:"30px"}}>
                        <div style={{ fontFamily: 'Poppins', fontSize: '20px', fontStyle: 'normal', fontWeight: 400, lineHeight: 'normal', textDecoration: 'none' }} >
                        Business Sign up
                        </div>

                      </Link>
                    
                      <Link className={`${styles.navItem}`} to="#" style={{paddingTop:"50px"}}>
                        <div style={{ fontFamily: 'Poppins', fontSize: '20px', fontStyle: 'normal', fontWeight: 400, lineHeight: 'normal', textDecoration: 'none' }} >
                          Blog
                        </div>

                      </Link>
                      <Link className={`${styles.navItem}`} to="/about-us" style={{paddingTop:"50px"}}>
                        <div style={{ fontFamily: 'Poppins', fontSize: '20px', fontStyle: 'normal', fontWeight: 400, lineHeight: 'normal', textDecoration: 'none' }} >
                          About us
                        </div>

                      </Link>
                      {/* <Link className={`${styles.navItem}`} to="/contact-us" style={{paddingTop:"50px"}}>
                        <div style={{ fontFamily: 'Poppins', fontSize: '20px', fontStyle: 'normal', fontWeight: 400, lineHeight: 'normal', textDecoration: 'none' }} >
                          Contact US
                        </div>

                      </Link> */}
                      
                    </div>
                  </div>
            </div>
          </div>
        </MaxWidth>
      </nav>

    </>
  );
};
