import React from 'react';

import { HomeLayout } from "../../views/HomeLayout";
import { Content } from "./components/Content/Content";



export const HomeLandingPage = () => {
    return (
        <HomeLayout isLoggedIn={false} isFooter={true}>
            <Content />
        </HomeLayout>
    );
};